import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import styles from "./PageHeader.module.scss";

class PageHeader extends React.Component {
    render() {
        const {
            alt,
            image
        } = this.props;

        return (
            <div className={styles.wrapper}>
                {image && image !== null &&
                    <GatsbyImage
                        image={image.childImageSharp.gatsbyImageData}
                        className={styles.image}
                        alt={alt}
                    />
                }
            </div>
        );
    }
};

PageHeader.propTypes = {
    alt: PropTypes.string,
    image: PropTypes.object
};

export default PageHeader;