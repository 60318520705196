import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { Breadcrumbs } from "../breadcrumbs/Breadcrumbs";

import classNames from "classnames/bind"; 
import styles from "./BodyHeader.module.scss";

class BodyHeader extends React.Component {
    render() {
        const {
            className,
            frontmatter,
            hideBreadcrumb
        } = this.props;

        const cx = classNames.bind(styles);

        const classes = cx(
            "wrapper",
            className
        );

        return (
            <div className={classes}>
                {!hideBreadcrumb &&
                    <Breadcrumbs
                        category={frontmatter.category}
                        type={frontmatter.type}
                    />
                }

                <h1 className={styles.title}>
                    {frontmatter.title}
                </h1>

                {frontmatter.type === "review" && frontmatter.media &&
                    <p className={styles.media}>{frontmatter.media} review</p>
                }

                <p className={styles.excerpt}>
                    {frontmatter.excerpt}
                </p>

                <p className={styles.meta}>
                    {frontmatter.date && frontmatter.dateSimple &&
                        <React.Fragment>
                            Published
                            {" "}
                            {frontmatter.author && frontmatter.author.friendlyName &&
                                <span className={styles.author}>
                                    by
                                    {" "}
                                    {frontmatter.author.page ?
                                        <Link
                                            to={frontmatter.author.page}
                                        >
                                            {frontmatter.author.friendlyName}
                                        </Link>
                                    :
                                        frontmatter.author.friendlyName
                                    }
                                </span>
                            }
                            {" "}
                            on
                            {" "}
                            <time className={styles.date} dateTime={frontmatter.dateSimple}>{frontmatter.date}</time>.
                            {" "}
                        </React.Fragment>
                    }
                    {frontmatter.lastUpdate && frontmatter.lastUpdateSimple &&
                        <React.Fragment>
                            Last updated <time className={styles.date} dateTime={frontmatter.lastUpdateSimple}>{frontmatter.lastUpdate}</time>.
                        </React.Fragment>
                    }
                </p>
            </div>
        )
    }
};

BodyHeader.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
    hideBreadcrumb: PropTypes.bool
};

export default BodyHeader;