import React from "react";
import { PostPreview } from "../post-preview/PostPreview";

import classNames from "classnames";
import styles from "./Posts.module.scss";

const Posts = ({
    className,
    data,
    hideAffiliate,
    hideCat,
    hideCopy,
    hideDate,
    hideThumb,
}) => {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        className,
    );

    return (
        <div className={classes}>
            {data.nodes.map(({ frontmatter, slug }) => (
                <div
                    className={styles.post}
                    key={slug}
                >
                    <PostPreview
                        hideAffiliate={hideAffiliate}
                        hideCat={hideCat}
                        hideCopy={hideCopy}
                        hideDate={hideDate}
                        hideThumb={hideThumb}
                        slug={`/${slug}`}
                        frontmatter={frontmatter}
                    />
                </div>
            ))}
        </div>
    );
};

export default Posts;