import React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../../components/layout/Layout";
import BodyHeader from "../../components/body-header/BodyHeader";
import PageHeader from "../../components/page-header/PageHeader";

import styles from "../pages.module.scss";
import Posts from "../../components/posts/Posts";

export const query = graphql`{
  summary: mdx(
    fileAbsolutePath: {regex: "src/data/copy/"}
    frontmatter: {title: {eq: "Bryan McDowall"}}
  ) {
    frontmatter {
      image {
        childImageSharp {
          gatsbyImageData(quality: 80, formats: [WEBP, JPG], layout: FULL_WIDTH)
        }
      }
      title
      category
      excerpt
      date(formatString: "MMM Do, YYYY")
      dateSimple: date(formatString: "YYYY-MM-DD")
      lastUpdate(formatString: "MMM Do, YYYY")
      lastUpdateSimple: lastUpdate(formatString: "YYYY-MM-DD")
      type
    }
    body
  }
  posts: allMdx(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {published: {eq: true}, type: {eq: "post"}, category: {eq: "personal"}}}
    limit: 5
  ) {
    nodes {
      slug
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(width: 254, quality: 80, formats: [WEBP, JPG], layout: CONSTRAINED)
          }
        }
        title
        date(formatString: "MMM Do, YYYY")
        dateSimple: date(formatString: "YYYY-MM-DD")
        category
        author {
          friendlyName
        }
        excerpt
      }
    }
  }

  portfolio: allMdx(
    sort: {fields: [frontmatter___released], order: DESC}
    filter: {frontmatter: {published: {eq: true}, type: {eq: "portfolio"}}}
  ) {
    nodes {
      slug
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(width: 254, quality: 80, formats: [WEBP, JPG], layout: CONSTRAINED)
          }
        }
        title
        date(formatString: "MMM Do, YYYY")
        dateSimple: date(formatString: "YYYY-MM-DD")
        released(formatString: "MMM Do, YYYY")
        releasedSimple: released(formatString: "YYYY-MM-DD")
        category
        excerpt
      }
    }
  }
}
`

const BryanProfile = ( props ) => {

    const { body, frontmatter } = props.data.summary;

    return (
        <Layout
            location={props.location}
            title={frontmatter.title}
            type={"page"}
            category={frontmatter.category}
            excerpt={frontmatter.excerpt}
            date={frontmatter.date}
            lastUpdate={frontmatter.lastUpdate}
        >
            <PageHeader
                image={frontmatter.image}
                alt={frontmatter.title}
            />

            <div className={styles.body}>
                <BodyHeader
                    frontmatter={frontmatter}
                />
                <MDXRenderer>{body}</MDXRenderer>

                {props.data.portfolio.nodes.length > 0 &&
                    <div className={styles.posts}>
                        <h2 className={styles.subtitle}>Portfolio</h2>
                        <Posts
                            data={props.data.portfolio}
                        />
                    </div>
                }
        
                {props.data.posts.nodes.length > 0 &&
                    <div className={styles.posts}>
                        <h2 className={styles.subtitle}>Personal posts</h2>
                        <Posts
                            data={props.data.posts}
                        />
                        <p className={styles.more}>
                            <Link
                                to="/personal/1"
                                className={styles.link}
                            >
                                More posts...
                            </Link>
                        </p>
                    </div>
                }
            </div>
        </Layout>
    )
}

export default BryanProfile;