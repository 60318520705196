import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import classNames from 'classnames/bind';
import styles from "./Category.module.scss";

export const Category = ({ className, children, noLink }) => {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        className,
        !noLink && "link"
    );

    return (
        noLink ?
            <span className={classes}>
                {children}
            </span>
        :
            <Link
                to={`/${children}/1`}
                className={classes}
            >
                {children}
            </Link>
    );
};
    
Category.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    noLink: PropTypes.bool
};

Category.defaultProps = {
    noLink: false,
}