import React from "react";
// import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { Category } from "../category/Category";

import classNames from "classnames/bind";
import styles from "./PostPreview.module.scss";

export const PostPreview = ({
    className,
    frontmatter,
    hideAffiliate,
    hideCat,
    hideCopy,
    hideDate,
    hideThumb,
    noLink,
    slug
}) => {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        className
    );

    const type = frontmatter.type;

    const thumbnail = {
        ...frontmatter.image.childImageSharp.gatsbyImageData,
        width: 254,
        height: 143
    }


    return (
        <div className={classes}>
            {!hideThumb &&
                <div className={styles.image}>
                    <Link
                        to={noLink ? null : slug}
                    >
                        {thumbnail !== null &&
                            <GatsbyImage
                                image={thumbnail}
                                alt={frontmatter.title}
                            />
                        }
                    </Link>
                </div>
            }

            <div className={styles.copy}>
                {!hideCat && frontmatter.category &&
                    <Category
                        className={styles.category}
                        noLink={type === "podcast"}
                    >
                        {type === "podcast" ?
                            frontmatter.series.title
                        :
                            frontmatter.category
                        }
                    </Category>
                }

                <Link 
                    to={noLink ? null : slug}
                    className={cx("titleLink", noLink ? "disabled" : null)}
                >
                    <h3 className={styles.title}>
                        {type === "podcast" &&
                            <span>S{frontmatter.season}E{frontmatter.episode}: </span>
                        }
                        {frontmatter.title}
                        {frontmatter.media && 
                            <span>{" "}[{frontmatter.media}]</span>
                        }
                    </h3>
                </Link>

                {frontmatter.type === "event" && frontmatter.platform && frontmatter.platform.length > 0 &&
                    <p className={styles.platforms}>[
                        {frontmatter.platform.map(plat =>
                            <span key={plat}>
                                {plat}
                            </span>
                        )}
                    ]</p>
                }

                {/* TODO: More robust conditions for when to show start time vs dates eg. EventIndex.js */}
                {/* TODO: Create Global Timezone property in config */}
                <p className={styles.meta}>
                    {hideDate ?
                        <React.Fragment>
                            {frontmatter.startTime && frontmatter.type === "event" &&
                                <time className={styles.date} dateTime={`${frontmatter.startTime}`}>{frontmatter.startTime}</time>
                            }
                            {frontmatter.endTime && frontmatter.type === "event" &&
                                <React.Fragment>
                                <span className={styles.date}>-</span><time className={styles.date} dateTime={`${frontmatter.endTime}`}>{frontmatter.endTime}</time>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    :
                        <React.Fragment>
                            {frontmatter.startDate && frontmatter.startDateSimple ?
                                <span className={styles.date}>
                                    <time dateTime={frontmatter.startDateSimple}>{frontmatter.startDate}</time>
                                    {frontmatter.endDate && frontmatter.endDateSimple ?
                                        <React.Fragment>
                                        {' '}- <time dateTime={frontmatter.endDateSimple}>{frontmatter.endDate}</time>
                                        </React.Fragment>
                                    : frontmatter.startTime && frontmatter.endTime ?
                                        <React.Fragment>
                                            {' '}- <time dateTime={frontmatter.startTimeSimple}>{frontmatter.startTime}</time> to <time dateTime={frontmatter.endTimeSimple}>{frontmatter.endTime}</time>
                                        </React.Fragment>
                                    : frontmatter.startTime ?
                                        <React.Fragment>
                                            {' '}- <time dateTime={frontmatter.startTimeSimple}>{frontmatter.startTime}</time>
                                        </React.Fragment>
                                    : null }
                                </span>
                            :frontmatter.released && frontmatter.releasedSimple ?
                                <span className={styles.date}>launched: <time dateTime={frontmatter.releasedSimple}>{frontmatter.released}</time></span>
                            : frontmatter.date && frontmatter.dateSimple ?
                                <time className={styles.date} dateTime={frontmatter.dateSimple}>{frontmatter.date}</time>
                            : null}
                        </React.Fragment>
                    }
                </p>

                {frontmatter.location && frontmatter.type === "event" &&
                    <p className={styles.date}>
                        {frontmatter.location}
                    </p>
                }
                
                
                {!hideCopy && frontmatter.excerpt &&
                    <p className={styles.excerpt}>{frontmatter.excerpt}</p>
                }

                {!hideAffiliate && frontmatter.productUrl && frontmatter.type === "event" &&
                    <p className={styles.affiliate}>
                        <a href={frontmatter.productUrl} target="_blank" rel="noreferrer">Check prices</a>
                    </p>
                }
            </div>
        </div>
    );
}

// PostPreview.propTypes = {
    
// };