import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import classNames from 'classnames/bind';
import styles from "./Breadcrumbs.module.scss";

export const Breadcrumbs = ({
    category,
    className,
    media,
    series,
    showHome,
    type
}) => {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        className
    );

    return (
        <div className={classes}>
            {showHome &&
                <div className={styles.part}>
                    <Link
                        className={styles.link}
                        to="/"
                    >
                        Home
                    </Link>
                </div>
            }
            {type && type.length > 0 &&
                <div className={styles.part}>
                    /
                    {type !== "about" ?
                        <Link
                            className={styles.link}
                            to={`/${type}s/1`}
                        >
                            {type}s
                        </Link>
                    :
                        <Link
                            className={styles.link}
                            to="/about"
                        >
                            About
                        </Link>
                    }
                </div>
            }
            {category && category.length > 0 &&
                <div className={styles.part}>
                    /
                    <Link
                        className={styles.link}
                        to={`/${category}/1`}
                    >
                        {category}
                    </Link>
                </div>
            }
            {media && media.length > 0 &&
                <div className={styles.part}>
                    /
                    <Link
                        className={styles.link}
                        to={`/${media.toLowerCase()}/1`}
                    >
                        {media}
                    </Link>
                    
                </div>
            }
            {series && series.length > 0 &&
                <div className={styles.part}>
                    /
                    <Link
                        className={styles.link}
                        to={`/${series}/1`}
                    >
                        {series}s
                    </Link>
                    
                </div>
            }

            {/* / About
            / Bryan */}
        </div>
    );
};
    
Breadcrumbs.propTypes = {
    className: PropTypes.string,
    showHome: PropTypes.bool,
    type: PropTypes.string,
    category: PropTypes.string,
    media: PropTypes.string,
    series: PropTypes.string,
};

Breadcrumbs.defaultProps = {
    showHome: true
}

